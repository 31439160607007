.card-link {
  display: block;
  color: #50a8ff;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.card-link:visited {
  color: #50a8ff;
}
