.card-cont {
    min-width: calc(100vh - 100px);
    margin-left: 50px;
}

.card {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    width: 200px;
    height: 280px;
    overflow: hidden;
    
  }
  
  .card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }

  .name-box-cont {
      height: 80px;
  }

  .name-box {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 10px;
    font-weight: bold;
    
  }

  .no-image-text {
      margin-top: 50%;
      font-weight: bold;
      
  }

  .card-picture {
    width: 198px;
    height: 200px;
    /* border: 1px solid gray;*/
    border-bottom: 1px solid gray; 
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    background-color: #ccdaff;
  }

  .no-picture-placehoder {
    width: 200px;
    height: 200px;
  }

  .card-detail {
      background-color: white;
      max-width: 200px;
  }

  .card-user-status {
    position: absolute;
    right: 0px;
    color: #FFF;
    width: 100px;
    border: 1px solid #FFF;
    border-radius: 5px;
    font-weight: bold;
  }

  .card-user-status-active {
    background-color: green;
  }

  .card-user-status-inactive {
    background-color: #545b62;
  }

  .modal-card-picture {
    width: 100px;
    height: 100px;
    /* border: 1px solid gray;*/
    border: 2px solid gray; 
    border-radius: 5px;
    background-color: #ccdaff;
    
  }

  .modal-no-image-text {
    margin-top: 35px;
    font-weight: bold;
}

.modal-card-menu-tab-btn-cont {
    width: 100px;
}

.modal-card-menu-tab-btn {
    width: 100px;
    margin-top: 20px;
    padding: 0;
    margin-left: 0;
}
