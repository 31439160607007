@import './utilities/colors.css';

.App {
  background-color: #f7f7f7;
  min-height: calc(100vh - 80px);
}

.App-logo {
  height: 160px;
  margin-bottom: 20px;
}

.App-header {
  background-color: #282c34;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10vmin;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.rb-label-input {
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.rb-textbox-style {
  margin: 0;
  color: #4b5668;
  font-family: 'Work Sans';
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
}

.rb-margin-zero {
  margin: 0;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.card-form {
  max-width: 500px;
  justify-content: center;
  width: 50%;
  min-width: 185px !important;
}

#notificationBar {
  -moz-animation: cssAnimation 0s ease-in 3s forwards;
  /* Firefox */
  -webkit-animation: cssAnimation 0s ease-in 3s forwards;
  /* Safari and Chrome */
  -o-animation: cssAnimation 0s ease-in 3s forwards;
  /* Opera */
  animation: cssAnimation 0s ease-in 3s forwards;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
@keyframes cssAnimation {
  to {
    width: 0;
    height: 0;
    overflow: hidden;
  }
}
@-webkit-keyframes cssAnimation {
  to {
    width: 0;
    height: 0;
    visibility: hidden;
  }
}

.react-datepicker-wrapper {
  width: 100%;
}
.form-control {
  border-radius: 9px !important;
  width: 100%;
  min-width: 183px;
}

.form-group > label {
  color: #4b5668;
  font-family: 'Work Sans';
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
}

.label {
  color: #4b5668;
  font-family: 'Work Sans';
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
}

.form-group > span {
  color: #4b5668;
  font-family: 'Work Sans';
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
}

.react-datepicker__input-container > input {
  border-radius: 9px !important;
  width: 100%;
  min-width: 183px;
  height: 38px;
}

.dropdown-toggle {
  border-radius: 9px !important;
  width: 100%;
  min-width: 183px;
  height: 38px;
  background-color: white !important;
  color: black !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  border: 1px solid #ced4da !important;
}

.dropdown {
  width: 100%;
}

.dropdown-toggle:focus {
  color: #495057 !important;
  background-color: #fff !important;
  border-color: #8995aa !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0.2rem rgba(75, 86, 104, 0.25) !important;
}
