@import '../../utilities/colors.css';

.inventory-header-container {
  min-height: 64px;
  background-color: var(--header-grey);
  display: flex;
  align-items: center;
  color: var(--grey);
  padding: 16px 0;
}

.filter-fields-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.inventory-header {
  display: flex;
  align-items: center;
}

.back-container {
  min-width: 32px;
  width: 32px;
  height: 32px;
  display: flex;
  background-color: white;
  border-radius: 30%;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  cursor: pointer;
}

.filter-button {
  min-width: 32px;
  width: 32px;
  height: 32px;
  display: flex;
  background-color: white;
  border-radius: 30%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.filter-button-container {
  justify-content: flex-end;
  align-items: center;
}

.clipboard-container {
  font-size: 24px;
  margin-right: 16px;
}

.inventory-title {
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 28px;
  margin-right: 16px;
}

.bag {
  border-radius: 16px;
  padding: 3px 16px;
  border: 1px solid #a5abb4;
  color: #a5abb4;
}

.filter-container {
  padding-top: 24px;
  padding-bottom: 24px;
  align-items: center;
}

.filter-buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.add-new-button {
  height: 48px;
  width: 120px;
  border-radius: 8px;
  background-color: var(--blue-primary);
  box-shadow: 0 1px 14px 0 rgba(234, 234, 255, 0.29);
  border: 0px;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
}

.add-new-button-fixed {
  height: 64px;
  width: 64px;
  border-radius: 50%;
  background-color: var(--blue-primary);
  position: fixed;
  bottom: 16px;
  z-index: 1;
  right: 16px;
  border: 0px;
  color: #ffffff;
  padding-top: 4px;
}

.search-input {
  height: 40px !important;
  border: 1px solid var(--grey-input) !important;
  border-radius: 8px !important;
  color: var(--grey) !important;
  background-color: transparent !important;
}

::placeholder {
  color: var(--grey-input) !important;
}

/* enable absolute positioning */
.inner-addon {
  position: relative;
  font-family: 'Work Sans';
  color: var(--grey-input);
}

/* style glyph */
.inner-addon .fa {
  position: absolute;
  padding: 12px;
  pointer-events: none;
}

/* align glyph */
.left-addon .fa {
  left: 0px;
}
.right-addon .fa {
  right: 4px;
}

/* add padding  */
.left-addon input {
  padding-left: 30px;
}
.right-addon input {
  padding-right: 30px;
}

.custom-control-input {
  height: 18px;
  width: 18px;
  border: 2px solid #a5abb4;
  border-radius: 5px;
}

.label {
  margin: 0;
  margin-right: 16px !important;
}

.select-filter {
  margin-left: 12px;
  font-weight: bold;
  color: var(--grey) !important;
}

.action-button {
  cursor: pointer;
  height: 32px;
  width: 32px;
  border-radius: 10px;
  background-color: #c0ffa9;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.inventory-card {
  text-align: center;
  border-radius: 9px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(184, 184, 184, 0.5);
  margin-bottom: 30px;
  padding: 16px;
}

.see-more {
  height: 34px;
  width: 34px;
  background-color: #ffffff;
  box-shadow: 0 0 0 1px rgba(184, 184, 184, 0.5);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  bottom: -9px;
  cursor: pointer;
}

.see-more-container {
  display: flex;
  justify-content: center;
  height: 8px;
}

.card-grey {
  color: var(--grey-card-brand);
}

.card-blue {
  color: var(--blue-primary);
}

.card-font-size-min {
  font-size: 14px;
}

.location-icon {
  margin-right: 4px;
}

.add-new-button-icon {
  font-size: 20px;
}

.order-button {
  box-sizing: border-box;
  border: 1px solid var(--grey-input);
  border-radius: 10px;
  color: var(--grey);
  width: 32px;
  height: 32px;
  min-width: 32px;
  font-size: 21px;
}

.inventory-user{
  border-radius: 8px;
  background-color: #FFFFFF;
  /* box-shadow: 0 1px 14px 0 rgba(234,234,255,0.29); */
  box-shadow: 0 0 4px 0 rgba(0,0,0,0.15);
  width: 100%;
  max-width: 14rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: fit-content;
  padding: 8px;
}

.user-name{
  color: #4B5668;
  font-family: "Work Sans";
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
}