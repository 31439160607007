/* .button-card-container {
    background-color: white;
    margin: 10px;
    width: 18rem;
    height: 13rem;
    border-radius: 3px;
} */

.button-card-container-min {
}

.button-card-link-min {
  opacity: 0.8;
  color: #181818;
  font-family: 'Work Sans';
  font-size: 16px;
  letter-spacing: 0;
  line-height: 19px;
  text-decoration-line: underline;
}
.button-card-bg-image {
  width: 100%;
  height: 80px;
  opacity: 0.7;
  background-size: cover;
  border-bottom: 2px solid #ababab;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  cursor: pointer;
}

.button-card-icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid #ababab;
  position: relative;
  margin-top: -30px;
  background-color: white;
  cursor: pointer;
}

.button-card-icon-min {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: relative;
  float: left;
  vertical-align: middle;
  box-shadow: 0 2px 4px 0 rgba(184, 184, 184, 0.5);
  background-color: white;
}

.button-card-title {
  margin-top: 0.5rem;
  font-weight: bold;
  cursor: pointer;
}

.button-card-title-min {
  color: #4b5668;
  font-family: 'Work Sans';
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
  text-align: left;
  margin-bottom: 4px;
}

.button-card-description {
  color: #828282;
  height: 50px;
  overflow: auto;
}

.button-card-description-min {
  opacity: 0.8;
  color: #4b5668;
  font-family: 'Work Sans';
  font-size: 16px;
  letter-spacing: 0;
  line-height: 19px;
  text-align: left;
  margin-bottom: 8px;
}

.button-content-main {
  padding-right: 10px;
  padding-left: 10px;
}

.card-container {
  display: flex;
  margin: 1em 0;

}

.img-container {
  display: flex;
  align-items: flex-start;
  margin-right: 24px;
}
.content-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.content-container-external {
  justify-content: start;
}

.img-card-external {
  width: 72px;
  height: 72px;
  border-radius: 20%;
}
