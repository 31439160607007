.margin-navbar {
  margin-top: 10vh;
}

.login-form-main {
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 855px;
  font-size: 15px;
  color: white;
  width: 80%/*50vw/*350px*/;
  margin: auto;
  padding-top: 40px;
  background: rgba(35, 35, 35, 0.5);
  border: 1px solid #65676b;
  border-radius: 2px;
  margin-top: 10vh;
  border-radius: 9px;
}

.forgot-pw-form-main {
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;

  font-size: 15px;
  color: white;

  width: 80%/*50vw/*350px*/;
  margin: auto;
  padding-top: 40px;
  /* background: rgba(35, 35, 35, 0.5);
  border: 1px solid #65676b; */
  border-radius: 2px;
  margin-top: 10vh;
  border-radius: 9px;
  background-color: white; 
  border: none; 
  display: flex; 
  padding-bottom: 65px;
}

.email-label {
  height: 24px;
  color: #4B5668;
  font-family: "Work Sans";
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
  color: var(--grey) !important;
  margin-left: -7%!important;
}

.email-label-forgot-pw {
  height: 24px;
  color: #4B5668;
  font-family: "Work Sans";
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
  color: var(--grey) !important;
  margin-left: -30%!important;
}

.error-message-forgot-pw {
  height: 24px;
  color: #4B5668;
  font-family: "Work Sans";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 19px;
  color: red !important;
  margin-left: -30%!important;
}

.title-forgot-pw {
  color: var(--grey);
  font-family: 'Work Sans';
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 4px;
  line-height: 19px;
  flex: 1;
  text-align: center;
}

.input-login {
  width: 110%!important;
  margin-left: -7%!important;
}

.forgot-pw-image {
  display: inline;
}

.text-forgot-pw {
  font-family: 'Work Sans';
  font-size: 16px;
  line-height: 19px;
  color: #4B5668;
  flex: 1;
  text-align: center;
  letter-spacing: 4px;
  margin-top: 0px;
}

.forgot-pw-placeholder {
  color: #A5ABB4;
  font-family: "Open Sans";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
  width: 150%!important;
  margin-left: -30%!important;
}

.back-to-login {
  height: 16px;
  width: 150px;
  color: #50A8FF!important;
  font-family: "Work Sans";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  margin-right: 24px;
}

.back-to-login:hover, .back-to-login:focus {
  text-decoration: underline;
  cursor: pointer;
}

.forgot-pw-buttons {
  display: flex;
}
/* .buttons-mobile {
  display: none;
}
.back-to-login-mobile {
  display: none;
} */

@media only screen and (max-width: 768px) {
  .forgot-pw-form-main {
    padding-bottom: 20px;
    min-height: 40vh;
  }

  .back-to-login {
    margin-right: 0px;
    margin-left: 0px;
    margin-left: 25%;
  }

  .margin-navbar {
    margin-top: 5vh;
  }

  .login-form-main {
    margin-top: 5vh;
    padding-top: 20px;
  }

  .App-logo {
    height: 80px;
    margin-bottom: 20px;
  }

  .div-email {
    max-width: 60%;
  }

  .forgot-pw-placeholder {
    margin-left: 10%!important;
  }

  .email-label-forgot-pw {
    margin-left: 10%!important;
  }

  .error-message-forgot-pw {
    margin-left: 10%!important;
  }
}

/* 
@media only screen and (max-width: 1000px) {
  .margin-navbar {
    margin-top: 5vh;
  }
  .login-form-main {
    width: 80%;
    margin-top: 5vh;
    height: 50vh
  }
  .title-forgot-pw {
    font-size: 16px;
  }
  .text-forgot-pw {
    margin-top: 16px;
  }
  .forgot-pw-image {
    display: none;
  }
  .forgot-pw-buttons {
    display: none;
  }
  .buttons-mobile {
    display: flex;
    align-items: center;
    margin-top: -15px;
  }
  .back-to-login-mobile {
    height: 16px;
    width: 90px;
    color: #50A8FF;
    font-family: "Work Sans";
    font-size: 14px;
    letter-spacing: 0;
    line-height: 16px;
  }
} */