@import '../../../utilities/colors.css';
.step-container {
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(184, 184, 184, 0.5);
  padding: 16px;
  margin: 16px;
  width: 94% !important;
  margin-bottom: 2em;
}

.welcome-title {
  font-size: 40px;
  font-weight: 400;
}
.welcome-subtitle {
  font-size: 24px;
  font-weight: bold;
}
.welcome-description {
  font-size: 16px;
}
.next-button {
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  height: 48px;
  max-width: 308px;
  border: none;
  border-radius: 8px;
  background-color: var(--blue-primary);
  box-shadow: 0 1px 14px 0 rgba(234, 234, 255, 0.29);
  position: relative;
  bottom: -40px;
  width: 100%;
}
.next-button-icon {
  position: relative;
  left: 9px;
}
.step-dots-container {
  margin: 16px;
  /* width: 90% !important; */
}
.step-dot {
  height: 40px;
  width: 40px;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 28px;
  text-align: center;
  border-radius: 50%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #d1d6dd;
}

.step-dot-line {
  position: absolute;
  width: 50%;
  background-color: #ededed;
  height: 2px;
  top: 50%;
}

.onboarding-title {
  padding: 15px;
  font-weight: bold;
}

.onboarding-profile-image {
  box-sizing: border-box;
  height: 160px;
  width: 160px;
  border: 2px solid var(--blue-primary);
  border-radius: 50%;
  background-image: url('../../../assets/images/onboarding-profile-default-image.png');
  background-repeat: round;
}
.upload-button {
  height: 47px;
  width: 100%;
  color: var(--blue-primary) !important;
  font-size: 16px !important;
  letter-spacing: 0;
  line-height: 19px !important;
  text-align: center;
  box-sizing: border-box !important;
  border: 1px solid var(--blue-primary) !important;
  border-radius: 8px !important;
  background-color: white !important;
  display: flex !important;
  justify-content: space-around !important;
  align-items: center !important;
}

.upload-button-image {
  max-width: 310px;
}

.upload-button-text {
  position: relative;
  width: 80%;
  text-align: center;
  text-transform: none;
}

.certificate-link {
  color: #bec1c6;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
}
.show-pass {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  margin-left: 12px;
  cursor: pointer;
  width: 18px;
}

.step-text {
  color: #4b5668;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
}

.confirm-onboarding{
  color: #4b5668;
  letter-spacing: 0;
}

.confirm-onboarding > h1 {
  
  font-size: 24px;
  font-weight: bold;
  line-height: 28px;
}

.confirm-onboarding > p {
  font-size: 16px;
  line-height: 19px;
}
