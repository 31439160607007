@import '../../utilities/colors.css';

.navbar-container {
  height: 64px;
  background-color: white;
  display: flex;
  align-items: center;
}

.navbar-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  color: var(--grey);
  font-family: 'Work Sans';
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 4px;
  line-height: 19px;
  flex: 1;
  text-align: center;
}

.logout-container {
  flex: 1;
  text-align: right;
}

.logout {
  font-size: 32px;
  cursor: pointer;
  color: var(--grey);
}

.user-profile-container {
  flex: 1;
}
.user-profile-text {
  color: var(--grey);
  font-size: 16px;
  letter-spacing: 0;
}
.profile-image {
  height: 48px;
  width: 48px;
  border-radius: 24px;
  margin-right: 1vw;
}
