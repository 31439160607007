@import '../../utilities/colors.css';

.main-container {
  margin-top: 40px;
}

.section-title {
  color: var(--grey);
  font-family: 'Work Sans';
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 28px;
}


