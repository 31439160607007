@import '../../../utilities/colors.css';

.modal-header {
  background-color: var(--header-grey);
  color: var(--grey);
  align-items: center !important;
  height: 76px;
  border-top-left-radius: 9px !important;
  border-top-right-radius: 9px !important;
  border: none !important;
}

.header-icon {
  font-size: 24px;
  color: var(--grey-card-brand);
}

.modal-close {
  min-width: 32px;
  width: 32px;
  height: 32px;
  display: flex;
  background-color: var(--grey-close-modal);
  border-radius: 30%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.modal-content {
  border: none !important;
  border-radius: 9px !important;
}

.modal-footer {
  justify-content: center !important;
  background-color: var(--header-grey);
  border: none !important;
  border-radius: 0 0 9px 9px !important;
}

.modal-save-button {
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  height: 48px;
  width: 308px;
  border: none;
  border-radius: 8px;
  background-color: var(--blue-primary);
  box-shadow: 0 1px 14px 0 rgba(234, 234, 255, 0.29);
  
}

.modal-label {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
  color: var(--grey);
}

.form-control-modal {
  border-radius: 9px !important;
  width: 100%;
  min-width: 183px;
}

.modal-body > .row > .col-12 {
  display: flex;
  justify-content: center;
}
